// Things related to Downloads page
const Downloads = {};

// Filters and sorts the entries for the download page
Downloads.filterAndSortEntries = function(entries)
{
	// We only want to show entris coming from Arkio
	// That is entries that do not have the ArkioImport field
	const filterMethod = function(entry)
	{
		if (entry.entryValue.File != null)
		{
			return entry.entryValue.File.ArkioImport == null;
		}
		return false;
	};
	entries = entries.filter(filterMethod);
	entries = Cloud.sortEntriesByDate(entries);
	return entries;
};

Downloads.fillTable = async function()
{
	try {
		let indexFile = await getIndexFile(showErrorMessage);
		if (indexFile != null) {
			let tableBody = document.getElementById('DownloadsTableBody');
			let entries = indexFile.GetEntriesAsArray();
			entries = Downloads.filterAndSortEntries(entries);
			let tableData = await createTableData(entries, null);
			console.log(tableData);
			fillTableWithEntries(tableBody, tableData, false, true, false, false, showErrorMessage, showWarningMessage, null);
		}
	}
	catch (error) {
		console.error("Failure when trying to fill table!", error);
		showErrorMessage("An unexpected error occurred while gathering data!");
	}
};

// Update UI of Downloads page
Downloads.updateUI = function()
{
	Downloads.fillTable();
};

Downloads.bodyOnLoad = function()
{
	let linked = isLinked();
	if (!linked)
	{
		// Redirect to manage group page if not connected to group
		goToGroupPage();
	}

	addGroupIdToTabLinksIfNeeded();
	Downloads.updateUI();
};
